<template>
  <div>
    <div v-if="!noReceiveSubmitFlag">
      <header v-if="verifyUpFlag">
        <div id="header_nav">
          <div class="title font_content">{{title_context}}</div>
          <div class="title_amount">₹{{amount}}</div>
          <div class="count font_content">{{formattedMinutes}}:{{formattedSeconds}}</div>
        </div>
      </header>
      <main  id="main" v-if="verifyUpFlag">
        <div>
          <div id="receive_area">
            <div>Chips you will receive: {{chips}} + 5% extra = {{totalChips}}.</div>
          </div>


          <div id="payment_method_root">
            <div id="payment_content">
              <div id="payment_content_green" class="font_content">
                <div id="payment_content_green_text">
                  Transfer to UPI ID
                </div>
              </div>

              <div id="upi_container2">
                <div class="font_content" style="color:#797979; margin-top: 10px;">
                  Copy the payee's UPI ID
                </div>
                <div id="upi_copy_container">
                  <div class="font_content_bold upi_content" style="color:black">
                    {{upi}}
                  </div>
                  <div class="copy_blue_container" @click="copyFun(upi, 'UPI')" :style="{ background: had_copy ? '#00BA3833' : '#1677FF' }">
                    <div v-show="!had_copy" class="copy_text">Copy</div>
                    <div v-show="had_copy" class="copy_text" style="color:#53b74c">Copied</div>
                  </div>

                  <img id="need_copy_hand" v-show="need_copy_upi_first" src="/assets/imgs/hand.png" alt="hand" />
                </div>

              </div>

              <div class="line"></div>

              <div id="payment_method_root2" ref="upi_shake_ref">
                <div class="font_content" style="color: #797979;font-size: 16px;margin-bottom: 10px">Choose one UPI App to transfer</div>
                <div id="hot_method_container" class="payment_method_container" @click="topay('paytmmp://upi')">
                  <img src="/assets/imgs/paytm.png" class="payimg" />
                  <span>Paytm</span>
                  <div id="hot_div">
                    <span class="font_content">Hot</span>
                  </div>
<!--                  <div id="hot_s" v-show="paytm_cash_wallet">Hot</div>-->
                  <img src="/assets/imgs/right_vector.png" class="right_vector" />
                </div>
                <div  class="payment_method_container" @click="topay('phonepe://pay')" >
                  <img src="/assets/imgs/phonepe.png" class="payimg"/>
                  <span>PhonePe</span>
                  <img src="/assets/imgs/right_vector.png" class="right_vector" />
                </div>
                <div  class="payment_method_container" @click="topay('gpay://upi')">
                  <img src="/assets/imgs/gpay.png" class="payimg"/>
                  <span>Google Pay</span>
                  <img src="/assets/imgs/right_vector.png" class="right_vector" />
                </div>

<!--                <div class="payment_method_container" @click="topay('upi://pay')">-->
<!--                  <img src="/assets/imgs/bhim1.png" class="payimg" />-->
<!--                  <span>BHIM</span>-->
<!--                  <img src="/assets/imgs/right_vector.png" class="right_vector" />-->
<!--                </div>-->
              </div>
            </div>
          </div>

          <div id="public_rules_steps">
            <div id="public_rules" @click="open_rules">
              <img class="public_img" src="/assets/imgs/public_rule.png" alt="rule"/>
              <div class="font_content_small" style="font-size: 12px">UPI Transfer Rules</div>
            </div>
            <div id="bet"></div>
            <div id="public_steps" @click="open_steps">
              <img class="public_img" src="/assets/imgs/public_steps.png" alt="rule"/>
              <div class="font_content_small" style="font-size: 12px">UPI Transfer Steps</div>
            </div>
          </div>

        </div>

        <div v-show="ruleFlag" class="ghost_modal" @click="close_rule"></div>
        <div id="rule_dialog" v-show="ruleFlag">
          <div class="rule_dialog_container">
            <div class="font_content_bold" style="color: black;margin: 20px 0">UPI Transfer Rules</div>
            <div class="rules_content">
              1. Please transfer correct amount of money to the payee's account above.
            </div>

            <div class="rules_content">
              2. The payee's account on this page is different for each recharge order. Do not make repeated payments.
            </div>

            <div  class="rules_content">
              3. Transfer before the countdown ends. If it ends, don't transfer and make a new recharge order.
            </div>

            <div class="rules_content">
              4. Wait patiently after transferring. If chips not received, please click the button below or contact customer service.
            </div>

            <div id="okay_button" class="font_content_bold" @click="close_rule">
              Okay
            </div>
          </div>

        </div>

        <div id="customer_container" @click="customer">
          <img
              :src="customerSrc"
              @mouseover="changeImage('hover')"
              @mouseleave="changeImage('default')"
              alt="customer image"
          />
        </div>
        <div id="bottom_tips">
          <img src="/assets/imgs/bottom_ok.png" alt="ok">
          <div>100% Secured Payment Gateway</div>
        </div>

      </main>
        <div id="click_not_received_container_root" v-if="verifyUpFlag">
          <div id="click_not_received_container" @click="open_submit">
            Paid, but not received?
          </div>
        </div>

      <div v-if="errorFlag" id="errorOrder" style="text-align: center; margin-top: 20px">
        <img src="/assets/imgs/under_review.png" />
        <div style="font-size: 28px;margin-top: 12px;margin-bottom: 2px">Try Again</div>
        <div style="font-size: 12px;color:#777777;">Unknown error.</div>
        <div style="font-size: 12px;color:#777777;">Please make a new recharge order!</div>
      </div>

      <div v-if="finishOrderFlag" id="finishOrder" style="text-align: center; margin-top: 20px">
        <img src="/assets/imgs/successful.png" />
        <div style="font-size: 28px;margin-top: 12px">Successfully</div>
        <div style="font-size: 12px;color:#777777;">Order completed</div>
      </div>

      <div v-if="submitFlag" id="submitFlag" style="text-align: center; margin-top: 20px">
        <img src="/assets/imgs/submit_under_review.png" />
        <div style="font-size: 28px;margin-top: 12px;margin-bottom: 2px">Under Review...</div>
        <div style="font-size: 12px;color:#777777;">We will check and confirm.</div>
        <div style="font-size: 12px;color:#777777;">Your chips will be added soon.</div>
      </div>


    </div>

    <div id="no_received_container" v-if="noReceiveSubmitFlag">
      <div id="no_received_header">
        <nav>
          <div class="title"><img src="/assets/imgs/lt.png" alt="lt" @click="close_submit" />&nbsp;&nbsp;<span style="padding-bottom:2px">Submit Evidence</span></div>
        </nav>
        <div id="submit_container">

          <div id="upload_image_container" :style="{ height: ocrErrorFlag ? '260px' : '190px' }">
            <div class="image_recognition" style="padding-left: 18px;padding-top: 18px; font-weight: 400; font-family: Arial; font-size: 20px;">Image Recognition</div>
            <div id="grey_container" @click="uploadShot">
              <img id="upload_screen_img" src="/assets/imgs/imgs.png" alt="upload" v-if="uploadFlag">
              <div v-if="ocrAmount">{{ocrAmount}}</div>
              <div v-if="ocrUtr">
                <span>{{ocrUtr}}</span>
                <img id="delete_screen_img" @click="delete_screen" src="/assets/imgs/delete.png" />
              </div>
            </div>
            <div id="ocr_error_div" v-if="ocrErrorFlag">
              <div>Image not recognized, please re-upload or enter manually.</div>
            </div>
          </div>

          <div id="submit_input_container">
            <div style="margin: 18px">
              <div class="input_line" style="border-bottom: 1px solid #eeeeee;">
                <div style="margin-right: 30px">UPI</div>
                <div class="custom-input-div">
                  <input v-model="submitUPI" class="custom-input" type="text" placeholder="Type receive UPI">
                </div>

              </div>
              <div class="input_line" style="padding-top: 2px;border-bottom: 1px solid #eeeeee;">
                <div style="margin-right: 2px">Amount</div>
                <div class="custom-input-div">
                  <input v-model="submitAmount" class="custom-input" type="text" @input="formatCurrency" placeholder="Type your amount">
                </div>

              </div>
              <div class="input_line" style="padding-top: 2px;">
                <div>UTR</div>
                <div class="custom-input-div" id="utr_input_div">
                  <input v-model="submitUtrVal" class="custom-input" type="text"  placeholder="Type your UTR">
                </div>

              </div>
            </div>

          </div>

          <div id="confirm_message">Please confirm that the payment amount and UTR are correct before submitting.</div>
        </div>
      </div>

      <div
          :class="['submit_button', isDisabled ? 'disabled' : '']"
          @click="submitUtr"
          @mouseover="submit_hover = true"
          @mouseleave="submit_hover = false"
          :style="{ backgroundColor: isDisabled ? 'gray' : (submit_hover ? '#2960d6' : '#1677FF') }"
      >
        <span style="color: white; font-size: 20px">Submit</span>
      </div>
    </div>

    <div v-show="guideFlag" class="ghost_modal" @click="close_guide_dialog"></div>
    <div id="guide_dialog" v-show="guideFlag">

      <div id="guide_dialog_container">
        <div id="guide_logo_container">
          <div id="guide_logo">
            <img id="icon" :src="current_icon" alt="icon" />
            <div style="color:#000000; font-size:20px;font-weight: bold">{{pay_type}}</div>
          </div>

          <img id="guide_close_img" src="/assets/imgs/guide_close.png" @click="close_guide_dialog" />
        </div>
        <video v-if="guideMediaType === 2" ref="myVideo" class="current_guide_img_class" controls :src="current_guide_video">
          <source type="video/mp4">
        </video>
        <iframe ref="youtubeMediaGuideDialog" v-if="guideMediaType === 1 && playYoutubeFlag" class="current_guide_img_class youtube_frame" id="ytplayer" type="text/html" height="400" width="220"
                :src="youtube_media"
                frameborder="0"></iframe>
        <div id="no_remindme_container">
          <input type="checkbox" id="reminderCheckbox" v-model="isChecked" @change="handleCheckboxChange" />
          <div id="not_remind_text">Do not remind me next time.</div>
        </div>

        <div id="transfer_container" @click="transferNow">
          <img class="pay_now_icon" :src="current_icon">
          <div class="font_content_bold" style="display: flex; align-items: center;margin-right: 16px" >
            <span>Pay Now</span>
            <img src="/assets/imgs/vector_right.png"  style="width: 24px; margin-left: 10px"/>
          </div>
        </div>
      </div>

    </div>


    <div v-show="stepsFlag" class="ghost_modal" @click="close_steps"></div>
    <div id="steps_dialog" v-show="stepsFlag">

      <div id="steps_dialog_container">
        <div id="steps_logo_container">
          <div class="steps_logo" @click="switch_steps(2)">
            <img src="/assets/imgs/phonepe.png" alt="steps" />
            <div v-show="steps_t === 2" class="under_steps"></div>
          </div>
          <div class="steps_logo" @click="switch_steps(1)">
            <img src="/assets/imgs/paytm.png" alt="steps" />
            <div v-show="steps_t === 1" class="under_steps"></div>
          </div>
          <div class="steps_logo" @click="switch_steps(3)">
            <img src="/assets/imgs/gpay.png" alt="steps" />
            <div v-show="steps_t === 3" class="under_steps"></div>
          </div>
<!--          <div class="steps_logo" @click="switch_steps(4)">-->
<!--            <img src="/assets/imgs/bhim1.png" alt="steps" />-->
<!--            <div v-show="steps_t === 4" class="under_steps"></div>-->
<!--          </div>-->
        </div>
        <video v-if="guideMediaType === 2" ref="myStepVideo" autoplay class="current_guide_img_class" controls :src="current_steps_guide_video">
          <source type="video/mp4">
        </video>
        <iframe ref="steps_youtube"  v-if="guideMediaType === 1 && playYoutubeFlag" class="current_guide_img_class" type="text/html" height="400" width="220"
                :src="youtube_steps_guide_media"
                frameborder="0"></iframe>

        <div id="okay_steps_button" @click="close_steps">
          <div id="transfer_now" >Okay
          </div>
        </div>
      </div>

    </div>

    <div v-show="tryDialogFlag" class="ghost_modal"></div>
    <div id="tryDialog" v-show="tryDialogFlag">
      <div id="tryDialogContainer">
        <img id="underreview_img" src="/assets/imgs/under_review.png">
        <div class="font_content" style="color: black">Try Again.</div>
        <div id="try_dialogcontent" style="font-size: 14px; color: #777777;">
          The order expired.<br>Please go back to click SVIP recharge button.<br>Make a new recharge order.
        </div>
      </div>
    </div>


    <input
        type="file"
        ref="fileInput"
        @change="screenshotUpload"
        style="display: none"
        accept="image/*"
    />

    <div class="message" id="message"></div>
  </div>

</template>

<style scoped>
* {
  font-family: Arial;
}
html {
  width: 100%;
  padding: 0;
  top: 0;
}
#guide_root {
  padding-bottom: 10px;
  background-color: white;
  width: 100%;
}
.guide_title {
  height: 8vh;
  background-color: #1677FF;
  color: white;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  font-family: Arial;
  margin-left: 0;
  display: flex;
  position: relative;
  justify-content: center;
}
.guide_title img {
  position: absolute;
  left: 20px;
  width: 12px;
}
#guide_container {
  display: flex;
  flex-direction: column;
}
.guide_item {
  margin-top: .8rem;
}

.guide_item_div {
  margin: .24rem 20px;
}

#guide_container div img {
  width: 100%;
}
#no_remindme_container {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  margin-left: 40px;
}
#not_remind_text {
  margin-left: 10px;
  margin-top: 2px;
  color: #8B8B8B;
  font-size: 10px;
  z-index: 102;
}

#no_remindme_container {
  accent-color: #00BA38;
  color: white;
}


/* 文本样式 */
#not_remind_text {
  font-size: 18px;
  color: #8B8B8B;
  margin-left: 10px;
  display: inline-block;
}

#transfer_container {
  background: #1677FF;
  height: 5.8vh;
  width: 90%;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative; /* 关键 */
  overflow: hidden; /* 避免扫光溢出容器 */
}

#transfer_container:hover {
  background: #136AD5;
}

#transfer_container::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
  transform: skewX(-20deg);
  animation: shine 1.5s infinite;
}

@keyframes shine {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

#icon {
  width: 32px;
  margin-left: 20px;
  margin-right: 10px;
}

#transfer_now {
  color: white;
  font-weight: bold;
  font-size: 20px;
}


input[type=checkbox] {
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  font-size: 12px;
}
input[type=checkbox]::after {
  position: absolute;
  top: 0;
  color: #000;
  width: 20px;
  height: 20px;
  display: inline-block;
  visibility: visible;
  padding-left: 0px;
  text-align: center;
  content: ' ';
  border-radius: 3px;
}

input[type=checkbox]:checked:after {
  content: '✓';
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  background-color: #00BA38;
}

</style>
<script>
import '../../public/assets/css/payClient.css'
import axios from "axios";
import toast from "@/toast";
// let commonUrl = 'http://127.0.0.1:8863'
// 测试环境
let commonUrl = 'https://svip-api.teamraid.xyz'
let reportUrl = 'https://svip-api.teamraid.xyz'
// 预发布环境
// let commonUrl = 'https://app-api-pre.tpmsvip.com'
// let reportUrl = 'https://svip-api.teamraid.xyz'
// 生产环境
// let commonUrl = 'https://app-api.tpmsvip.com'
// let reportUrl = 'https://app-api.tpmsvip.com'
export default {
  data() {
    return {
      ruleFlag: false,
      channel: 'svip',
      pn: 'svip',
      version: 'web',
      ga_time_str: '5s',
      submit_hover: false,
      title_context: 'Amount you need to pay',
      ga_time: 0,
      ga_time_in_id: null,
      amount: 0,
      up: null,
      guideFlag: false,
      totalChips: null,
      tryDialogFlag: false,
      wait_payment_dialog_flag: false,
      isButtonDisabled: true,
      utr: null,
      gtag: null,
      upi: null,
      uid: null,
      ocrErrorFlag: false,
      uploadFlag: true,
      ocrAmount: null,
      ocrUPI: null,
      ocrUtr: null,
      countdownInterval: null,
      submitAmount: null,
      submitUPI: null,
      steps_t: 2,
      had_copy: false,
      current_copy: '',
      need_copy_upi_first: false,
      screenshotUrl: null,
      verifyUpFlag: false,
      errorFlag: false,
      cpOrderNo: null,
      noReceiveSubmitFlag: false,
      customerSrc: '/assets/imgs/customer.png',
      svipOrderNo: '',
      upiFlag: false,
      submitRepeatFlag: false,
      toPayNowFlag: false,
      bankFlag: false,
      submitUtrVal: null,
      scanFinishOrderFlag: false,
      scheduling_type: null,
      paytm_cash_url: null,
      guide_steps: {
        paytmmp_steps: [
          '/assets/imgs/paytmmp_step1.png',
          '/assets/imgs/paytmmp_step2.png',
          '/assets/imgs/paytmmp_step3.png',
          '/assets/imgs/paytmmp_step4.png',
          '/assets/imgs/paytmmp_step5.png',
          '/assets/imgs/paytmmp_step6.png',
        ],
        phonepe_steps: [
          '/assets/imgs/phonepe_step1.png',
          '/assets/imgs/phonepe_step2.png',
          '/assets/imgs/phonepe_step3.png',
          '/assets/imgs/phonepe_step4.png',
          '/assets/imgs/phonepe_step5.png',
          '/assets/imgs/phonepe_step6.png',
          '/assets/imgs/phonepe_step7.png',
        ],
        gpay_steps: [
          '/assets/imgs/gpay_step1.png',
          '/assets/imgs/gpay_step2.png',
          '/assets/imgs/gpay_step3.png',
          '/assets/imgs/gpay_step4.png',
          '/assets/imgs/gpay_step5.png',
        ],
      },
      paytmmp_steps: ['/assets/imgs/paytmmp_step1.png','/assets/imgs/paytmmp_step2.png','/assets/imgs/paytmmp_step3.png','/assets/imgs/paytmmp_step4.png','/assets/imgs/paytmmp_step5.png','/assets/imgs/paytmmp_step6.png'],
      phonepe_steps: ['/assets/imgs/phonepe_step1.png','/assets/imgs/phonepe_step2.png','/assets/imgs/phonepe_step3.png','/assets/imgs/phonepe_step4.png','/assets/imgs/phonepe_step5.png','/assets/imgs/phonepe_step6.png','/assets/imgs/phonepe_step7.png'],
      gpay_steps: ['/assets/imgs/gpay_step1.png','/assets/imgs/gpay_step2.png','/assets/imgs/gpay_step3.png','/assets/imgs/gpay_step4.png','/assets/imgs/gpay_step5.png'],
      guide_type: '',
      steps_type: '',
      need_to_pay: '',
      pay_type: '',
      intervalId: null,
      minutes: null,
      seconds: null,
      expiredTime: null,
      expiredTs: null,
      chips: null,
      finishOrderFlag: false,
      elapsedTime: 0,
      paynow_flag: false,
      submitFlag: false,
      account: '',
      cardNo: '',
      ifsc: '',
      pkg: '',
      customer_token : '85bf4375-5073-45c1-b8bd-1c82c6f2592c',
      current_pay_url: '',
      isChecked: false,
      stepsFlag: false,
      paytm_cash_wallet: false,
      guideMediaType: 2,
      playYoutubeFlag: true
    }
  },
  methods: {

    switch_steps(t) {
      this.steps_t = t
      if(t === 1) {
        this.reportBuriedPoint('steps_paytm')
      } else if (t === 2) {
        this.reportBuriedPoint('steps_phonepe')
      } else if (t === 3) {
        this.reportBuriedPoint('steps_gpay')
      } else if (t === 4) {
        this.reportBuriedPoint('steps_BHIM')
      }
      this.playStepVideo()
    },
    close_rule() {
      this.ruleFlag = false
      this.reportBuriedPoint('rules_close')
    },
    close_steps() {
      this.stopStepVideo()
      this.stepsFlag = false
      this.reportBuriedPoint('steps_close')
    },
    open_rules() {
      this.ruleFlag = true
      this.reportBuriedPoint('click_rules_button')
    },
    open_steps() {
      this.stepsFlag = true
      this.playStepVideo()
      this.reportBuriedPoint('click_steps_button')
      this.reportBuriedPoint('steps_phonepe')
    },
    stopVideo() {
      if(this.guideMediaType === 2) {
        this.$refs.myVideo.pause();
      } else if (this.guideMediaType === 1) {
        this.playYoutubeFlag = false
      }
    },
    playVideo() {
      if(this.guideMediaType === 2) {
        this.$refs.myVideo.play();
      } else if (this.guideMediaType === 1) {
        this.playYoutubeFlag = true
      }
    },
    stopStepVideo() {
      if(this.guideMediaType === 2) {
        this.$refs.myStepVideo.pause();
      } else if (this.guideMediaType === 1) {
        this.playYoutubeFlag = false
      }
    },
    playStepVideo() {
      if(this.guideMediaType === 2) {
        this.$refs.myStepVideo.play();
      } else if (this.guideMediaType === 1) {
        this.playYoutubeFlag = true
      }
    },

    close_guide_dialog() {
      this.stopVideo()
      this.isChecked = false
      this.guideFlag = false
      this.reportBuriedPoint('close_tutorial')
      if(this.guide_type === 'paytmmp') {
        this.reportBuriedPoint('close_tutorial_paytm')
      } else {
        this.reportBuriedPoint('close_tutorial_' + this.guide_type)
      }
    },
    changeImage(state) {
      if (state === 'hover') {
        this.customerSrc = '/assets/imgs/customer1.png';
      } else {
        this.customerSrc = '/assets/imgs/customer.png';
      }
    },
    handleCheckboxChange() {
      if(this.isChecked) {
        localStorage.setItem('no_guide:' + this.guide_type, 1)
        if(this.guide_type === 'paytmmp') {
          sendGtagEvent('click_paytm_dont_remind')
          sendGtagEvent('click_paytm_dont_remind_' + this.ga_time_str)
          this.reportBuriedPoint('dont_remind_paytm')
        } else {
          sendGtagEvent('click_'+ this.guide_type +'_dont_remind')
          sendGtagEvent('click_'+ this.guide_type +'_dont_remind_' + this.ga_time_str)
          this.reportBuriedPoint('dont_remind_' + this.guide_type)
        }
      } else {
        localStorage.removeItem('no_guide:' + this.guide_type)
      }
    },
    back() {
      this.guideFlag = false
      this.verifyUpFlag = true
    },
    transferNow() {
      // this.guideFlag = false
      this.verifyUpFlag = true
      this.reportBuriedPoint('paynow')
      this.paynow_flag = true
      if(this.guide_type === 'paytmmp') {
        sendGtagEvent('click_paytm_from_tutorial')
        sendGtagEvent('click_paytm_from_tutorial_' + this.ga_time_str)
        this.reportBuriedPoint('paynow_paytm')
      } else {
        sendGtagEvent('click_'+this.guide_type+'_from_tutorial')
        sendGtagEvent('click_'+this.guide_type+'_from_tutorial_' + this.ga_time_str)
        this.reportBuriedPoint('paynow_' + this.guide_type)
      }
      setTimeout(()=> {
          window.location.href = this.current_pay_url
      }, 500)

    },

    build_paytm_cash_url() {
      this.paytm_cash_url = 'paytmmp://cash_wallet?pa=' + this.upi + '&mc=1771&am=' + this.amount + '&cu=INR&featuretype=money_transfer'
    },
    topay(url) {
      // 需要判断是否已经复制了UPI如果没有复制UPI则不能走到后面的引导步骤，需要展示提示复制的样式
      if(this.paytm_cash_wallet && url === 'paytmmp://upi') {
        console.log('no need first copy')
      } else {
        if(this.current_copy !== 'UPI') {

          if(this.need_copy_upi_first) {
            this.need_copy_upi_first = true
            const handElement = document.getElementById('need_copy_hand');
            handElement.style.animationDuration = '0.5s';
            setTimeout(()=> {
              handElement.style.animationDuration = '1s';
            }, 1500)
          } else {
            this.need_copy_upi_first = true

          }
          //需要标记一下
          this.need_to_pay = url
          this.reportBuriedPoint('copy_guide')
          if(url === 'phonepe://pay') {
            sendGtagEvent('click_phonepe')
            this.reportBuriedPoint('click_phonepe')
            this.reportBuriedPoint('click_phonepe_not_copied')
            this.reportBuriedPoint('copy_guide_phonepe')
          }else if (url === 'paytmmp://upi') {
            sendGtagEvent('click_paytm')
            this.reportBuriedPoint('click_paytm')
            this.reportBuriedPoint('click_paytm_not_copied')
            this.reportBuriedPoint('copy_guide_paytm')
          } else if (url === 'gpay://upi') {
            sendGtagEvent('click_gpay')
            this.reportBuriedPoint('click_gpay')
            this.reportBuriedPoint('click_gpay_not_copied')
            this.reportBuriedPoint('copy_guide_gpay')
          } else if (url === 'upi://pay') {
            sendGtagEvent('click_BHIM')
            this.reportBuriedPoint('click_gpay')
            this.reportBuriedPoint('click_BHIM_not_copied')
            this.reportBuriedPoint('copy_guide_BHIM')
          }
          return
        }
      }

      // pa=go.meme2781@upi&tn=4&pn=pay&am=49.97 todo
      // paytmmp://cash_wallet?pa=8502864807@ptsbi&pn=Owais&mc=1771&tr=41202&tn=41202&am=99.02&cu=INR&featuretype=money_transfer
      // this.current_pay_url = 'paytmmp://cash_wallet?pa=' + this.upi + '&mc=1771&am='+ this.amount +'&cu=INR&featuretype=money_transfer'
      // this.current_pay_url = url + '?pa=' + this.upi + '&am=' + this.amount
      this.current_pay_url = url
      if(url === 'phonepe://pay') {
        this.guide_type = 'phonepe'
        this.pay_type = 'PhonePe'
        sendGtagEvent('click_phonepe')
        sendGtagEvent('click_phonepe_' + this.ga_time_str)
        this.reportBuriedPoint('click_phonepe')
        this.reportBuriedPoint('click_phonepe_copied')
      } else if (url === 'paytmmp://upi') {
        this.guide_type = 'paytmmp'
        this.pay_type = 'Paytm'
        sendGtagEvent('click_paytm')
        sendGtagEvent('click_paytm_' + this.ga_time_str)
        this.reportBuriedPoint('click_paytm')
        this.reportBuriedPoint('click_paytm_copied')
        if(this.paytm_cash_wallet) {
          this.current_pay_url = this.paytm_cash_url
        }

      } else if (url === 'gpay://upi') {
        this.guide_type = 'gpay'
        this.pay_type = 'Google Pay'
        sendGtagEvent('click_gpay')
        sendGtagEvent('click_gpay_' + this.ga_time_str)
        this.reportBuriedPoint('click_gpay')
        this.reportBuriedPoint('click_gpay_copied')
      } else if (url === 'upi://pay') {
        this.guide_type = 'BHIM'
        this.pay_type = 'BHIM Pay'
        sendGtagEvent('click_BHIM')
        sendGtagEvent('click_BHIM_' + this.ga_time_str)
        this.reportBuriedPoint('click_BHIM')
        this.reportBuriedPoint('click_BHIM_copied')
      }

      let no_guide = localStorage.getItem('no_guide:' + this.guide_type)
      setTimeout(()=> {
        if(no_guide || (this.paytm_cash_wallet && url === 'paytmmp://upi')) {
          this.stopVideo()
          console.log('no_guide:', url)
          this.reportBuriedPoint('paynow')
          this.paynow_flag = true
          if(url === 'phonepe://pay') {
            this.reportBuriedPoint('paynow_phonepe')
          } else if (url === 'paytmmp://upi') {
            this.reportBuriedPoint('paynow_paytm')
          } else if (url === 'gpay://upi') {
            this.reportBuriedPoint('paynow_gpay')
          } else if (url === 'upi://pay') {
            this.reportBuriedPoint('paynow_BHIM')
          }
          setTimeout(()=> {
            window.location.href = this.current_pay_url;
          }, 500)
        } else {
          this.playVideo()
          this.guideFlag = true
          this.reportBuriedPoint('click_from_tutorial')
          if(url === 'phonepe://pay') {
            this.reportBuriedPoint('click_phonepe_from_tutorial')
          }else if (url === 'paytmmp://upi') {
            this.reportBuriedPoint('click_paytm_from_tutorial')
          } else if (url === 'gpay://upi') {
            this.reportBuriedPoint('click_gpay_from_tutorial')
          } else if (url === 'upi://pay') {
            this.reportBuriedPoint('click_BHIM_from_tutorial')
          }
          // this.verifyUpFlag = false
        }
      }, 500)
    },
    startTimer() {
      if (this.intervalId) {
        return;
      }

      this.intervalId = setInterval(() => {
        this.elapsedTime += 3;
        if(!this.finishOrderFlag && this.elapsedTime >= 1200) {
          clearInterval(this.intervalId);
          this.intervalId = null;
          console.log("scanOrder timeout！");
          return
        }

        if(!this.finishOrderFlag || this.verifyUpFlag) {
          this.scan_order()
        }

      }, 3000);
    },

    scan_order() {
      let scanOrderParam = {
        cpOrderNo: this.cpOrderNo
      }
      postRequest('/scheduling/scanFinishOrder', scanOrderParam).then(data => {
        if(data && data.code === 0) {
          if(data.data.status === 2) {
            this.finishOrderFlag = true
            if(this.intervalId) {
              clearInterval(this.intervalId);
              if(this.countdownInterval) {
                clearInterval(this.countdownInterval);
              }
            }

            this.verifyUpFlag = false
            this.errorFlag = false
            this.submitFlag = false
            this.reportBuriedPoint('order_success')
          } else if (data.data.orderEx) {
            console.log('data data orderEx')
            if(this.intervalId) {
              clearInterval(this.intervalId);
              if(this.countdownInterval) {
                clearInterval(this.countdownInterval);
              }
            }
            this.finishOrderFlag = false
            this.submitFlag = false
            this.verifyUpFlag = false
            this.errorFlag = true
          }
        }
      })
    },
    delete_screen() {
      this.submitAmount = null
      this.submitUtrVal = null
      this.ocrAmount = null
      this.ocrUPI = null
      this.submitUPI = null
      this.ocrUtr = null
      this.uploadFlag = true
      this.ocrErrorFlag = false
    },
    startCountdown() {
      this.countTime()
      this.countdownInterval = setInterval(() => {
        this.countTime()
      }, 1000)
    },

    countTime() {
      let now = Date.now();
      let timeLeft = this.expiredTs - now;
      if (timeLeft <= 0) {
        if(this.countdownInterval) {
          clearInterval(this.countdownInterval)
          if(this.intervalId) {
            clearInterval(this.intervalId)
          }
        }
        this.minutes = 0;
        this.seconds = 0;
        //如果finishOrder且没有submit的情况下，不改任何状态。
        if(!this.finishOrderFlag && !this.submitFlag) {
          // this.verifyUpFlag = false
          // this.errorFlag = true
          this.tryDialogFlag = true
          this.ruleFlag = false
          this.stepsFlag = false
          this.guideFlag = false
          this.stopVideo()
        }

      } else {
        this.minutes = Math.floor(timeLeft / 1000 / 60);
        this.seconds = Math.floor((timeLeft / 1000) % 60);
      }
    },
    submitUtr() {
      if(this.isDisabled) {
        // this.showMessage('Please provide the UTR and amount you have transferred')
        return
      }
      if(this.submitRepeatFlag) {
        console.log('submitRepeatFlag')
        return
      }
      this.submitRepeatFlag = true

      let utrParam = {
        svipOrderNo: this.svipOrderNo,
        screenshotUrl: this.screenshotUrl,
        submitAmount: this.submitAmount.replace('₹', ''),
        submitUtr: this.submitUtrVal,
        submitUPI: this.submitUPI
      }
      postRequest('/uploadUTR/submitUTR', utrParam).then(data=> {
        if(data && data.code === 0) {
          this.showMessage('submit success. we will verify order information and utr amount. Please wait.')

          if(this.intervalId) {
            clearInterval(this.intervalId);
            if(this.countdownInterval) {
              clearInterval(this.countdownInterval);
            }
          }
          setTimeout(() => {
            this.submitAmount = null
            this.submitUtrVal = null
            this.submitUPI = null
            this.screenshotUrl = null
            this.ocrAmount = null
            this.ocrUPI = null
            this.ocrUtr = null
            this.uploadFlag = true
            this.noReceiveSubmitFlag = false
            this.verifyUpFlag = false
            this.errorFlag = false
            this.submitFlag = true
          },3000)
        }
      })
      setTimeout(()=> {
        this.submitRepeatFlag = false
      }, 1000)
    },
    formatCurrency() {
      let rawValue = this.submitAmount.replace(/[₹\s]/g, '');
      if (!isNaN(rawValue) && rawValue !== '') {
        this.submitAmount = `₹${rawValue}`;
      } else {
        this.submitAmount = '';
      }
    },
    sendGtagEvent(action) {
      // 其他参数被设定为固定值
      const category = 'button';
      const label = 'myButton';
      const value = 1;
      // eslint-disable-next-line no-undef
      gtag('event', action, {
        'event_category': category,
        'event_label': label,
        'value': value
      });
      //
      // event(action, {
      //   // 事件名称，替换为你要跟踪的事件
      //   event_category: 'category', // 事件类别（可选）
      //   event_label: 'label', // 事件标签（可选）
      //   value: 1 // 事件值（可选）
      // })
    },
    screenshotUpload(event) {

      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();

        // 添加文件到 formData
        formData.append("file", file);

        // 添加其他参数
        formData.append("filename", this.svipOrderNo + Date.now());
        formData.append("svipOrderNo", this.svipOrderNo)
        // 发送 POST 请求
        fetch(commonUrl + "/uploadUTR/upload", {
          method: "POST",
          body: formData
        })
            .then(response => response.json())
            .then(data => {
              if(data && data.code === 0) {
                //展示解析出来的金额和utr，回写到submitAmount和submitUtr
                if(data.data.amount) {
                  this.submitAmount = '₹' + data.data.amount
                  this.ocrAmount = '₹' + data.data.amount
                }
                if(data.data.utr) {
                  this.submitUtrVal = data.data.utr
                  this.ocrUtr = data.data.utr
                }
                if(data.data.upi) {
                  this.submitUPI = data.data.upi
                  this.ocrUPI = data.data.upi
                }
                this.screenshotUrl = data.data.fileShowPath

                if(!data.data.amount && !data.data.utr) {
                  this.ocrErrorFlag = true
                  this.uploadFlag = true
                } else {
                  this.ocrErrorFlag = false
                  this.uploadFlag = false
                }
              } else {
                this.ocrErrorFlag = true
                this.uploadFlag = true
              }
            })
            .catch(error => {
              console.error("upload image failed: ", error);
              this.ocrErrorFlag = true
            });
      }
    },


  uploadShot() {
      this.$refs.fileInput.click();
    },

    getUp() {
      const urlParams = new Proxy({}, {
        get: (target, name) => name in target ? target[name] : ''
      });

      const currentURL = window.location.href;
      const parsedURL = new URL(currentURL);
      const params = parsedURL.searchParams;

      for (const [param, value] of params) {
        urlParams[param] = value;
      }
      return urlParams;
    },
    copyFun(target, msg) {
      if(msg === 'amount') {
        this.current_copy = 'amount'
        sendGtagEvent('copy_pay_amount')
        sendGtagEvent('copy_pay_amount_' + this.ga_time_str)
      } else if (msg === 'UPI') {
        sendGtagEvent('copy_pay_upi')
        sendGtagEvent('copy_pay_upi_' + this.ga_time_str)
        this.reportBuriedPoint('copy_upi')
      } else if (msg === 'account name') {
        this.current_copy = 'account name'
        sendGtagEvent('copy_pay_account_name')
        sendGtagEvent('copy_pay_account_name_' + this.ga_time_str)
      } else if (msg === 'account number') {
        this.current_copy = 'account number'
        sendGtagEvent('copy_pay_account_number')
        sendGtagEvent('copy_pay_account_number_' + this.ga_time_str)
      } else if (msg === 'IFSC') {
        this.current_copy = 'IFSC'
        sendGtagEvent('copy_pay_ifsc')
        sendGtagEvent('copy_pay_ifsc_' + this.ga_time_str)
      }

      // this.reportEvent('webUtr-copy-upi')/* 'webUtr-copy-upi' */
      var textarea = document.createElement("textarea");
      textarea.value = target;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      // navigator.clipboard.writeText(target).then(function() {
      // }).catch(function(err) {
      //   console.error('复制到剪贴板时发生错误: ', err);
      // });
      document.body.removeChild(textarea);
      if(msg == 'UPI') {
        this.current_copy = 'UPI'
        this.had_copy = true
        toast.show('The UPI ID has been copied.')

        const upiContainer = this.$refs.upi_shake_ref;
        upiContainer.classList.add('shake');
        upiContainer.addEventListener('animationend', () => {
          upiContainer.classList.remove('shake');
        });

        if(this.need_copy_upi_first) {
          this.reportBuriedPoint('copy_after_guide')
          this.need_copy_upi_first = false

        }
      } else {
        toast.show(msg + ' copied')
      }

      // var message = document.getElementById("message");
      // message.innerText = msg + " copied";
      // message.classList.add("show");
      // setTimeout(function () {
      //   message.classList.remove("show");
      // }, 3000);
    },

    showMessage(val) {
      var message = document.getElementById("message");
      message.innerText = val;
      message.classList.add("show");
      setTimeout(function () {
        message.classList.remove("show");
      }, 3000);
    },

    verifyUp() {
      return !!this.up.id;
    },
    uuid () {
      var s = []
      var hexDigits = '0123456789abcdef'
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      s[14] = '4'  // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)  // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = '-'

      var uuid = s.join('')
      return uuid
    },
    asncRequest (url, data, type) {
      return new Promise((resolve, reject) => {
        console.log(reject)
        var ajax = new XMLHttpRequest()
        ajax.open(type, url)
        ajax.setRequestHeader('content-type', 'application/json; charset=utf-8')
        ajax.send(data)
        ajax.onreadystatechange = function () {
          if (ajax.readyState === 4 && ajax.status === 200) {
            resolve(JSON.parse(ajax.responseText))
          }
        }
      })
    },
    reportBuriedPoint (key) {
      let now = (new Date().getTime()).toFixed(0)
      let useds = this.ga_time_str.substring(0, this.ga_time_str.length - 1)
      let sendData = JSON.stringify(
          {
            cts: now,
            pn: this.pn,
            channel: this.channel,
            version: this.version,
            useds: useds,
            pkg: this.pkg,
            upi: this.upi,
            uid: this.uid,
            amount: this.amount,
            cpOrder: this.cpOrderNo,
            event: key
          })
      this.asncRequest(reportUrl + '/behavior/report', sendData, 'POST').then((data) => {
        if (data) {
          console.log(key + ':' + ' report success')
        }
      })

    },
    //
    open_submit() {
      sendGtagEvent('click_not_received')
      sendGtagEvent('click_not_received_' + this.ga_time_str)
      this.reportBuriedPoint('click_not_received')
      this.noReceiveSubmitFlag = true
    },

    setCookie(name, value, hours) {
      const date = new Date();
      date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
      const expires = "expires=" + date.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
    },

    getCookie(name) {
      const nameEQ = name + "=";
      const cookiesArray = document.cookie.split(';');
      for(let i = 0; i < cookiesArray.length; i++) {
        let cookie = cookiesArray[i].trim();
        if (cookie.indexOf(nameEQ) === 0) {
          return cookie.substring(nameEQ.length, cookie.length);
        }
      }
      return null;
    },

    close_submit() {
      this.uploadFlag = true
      this.submitAmount = null
      this.submitUtrVal = null
      this.submitUPI = null
      this.ocrAmount = null
      this.ocrUPI = null
      this.ocrUtr = null
      this.noReceiveSubmitFlag = false
      this.ocrErrorFlag = false
    },

    parseSchedulingOrder() {
      let id =  this.up.id
      if(!id) {
        this.verifyUpFlag = false
        this.errorFlag = true
        return
      }
      //token
      // let t = null
      // let idmap = localStorage.getItem('idmap' + this.up.id)
      // if(idmap) {
      //   t = localStorage.getItem('so_token_' + idmap)
      // }

      id = encodeURIComponent(id)


      this.verifyVersion()
      let orderParam = {
        id: id,
        version: this.version
      }
      postRequest('/scheduling/parseOrder', orderParam).then((data) => {
        if(data.code === 0 && data.data) {
          //判断状态是否ok

          this.pn = data.data.pn
          this.channel = data.data.channel
          this.pkg = data.data.pkg
          let orderResult = data.data
          if(orderResult.status === 1 || orderResult.status === 2) {
            //tokenExpire
            this.uid = orderResult.uid
            if(orderResult.tokenExpire) {
              this.verifyUpFlag = false
              this.errorFlag = true
              return
            }
            if(orderResult.payType === 1) {
              this.upi = data.data.upi
              this.upiFlag = true
              this.bankFlag = false
              this.scheduling_type = 1
            } else {
              this.title_context = 'Bank Transfer'
              this.account = orderResult.account
              this.cardNo = orderResult.cardNo
              this.ifsc = orderResult.ifsc
              this.upiFlag = false
              this.bankFlag = true
              this.scheduling_type = 2
            }
            this.chips = orderResult.amount
            this.totalChips = orderResult.amount * 1.05
            this.amount = orderResult.realAmount
            this.svipOrderNo = orderResult.svipOrderNo
            this.cpOrderNo = orderResult.cpOrderNo
            this.guideMediaType = orderResult.guideMediaType
            // localStorage.setItem('idmap' + this.up.id, this.svipOrderNo)
            // localStorage.setItem('so_token_' + this.svipOrderNo, data.data.token)
            this.verifyUpFlag = true
            this.expiredTime = orderResult.expiredTime
            this.expiredTs = orderResult.expiredTs
            this.startCountdown()
            //开启扫描订单状态标记
            this.startTimer()

            if(orderResult.paytmCashWallet === 1) {
              this.paytm_cash_wallet = true
              this.build_paytm_cash_url()
              if(!this.tryDialogFlag) {
                var link = document.createElement('a')
                link.href = this.paytm_cash_url
              }
            }

          } else {
            //订单异常
            this.verifyUpFlag = false
            this.errorFlag = true
          }

          this.toPayNowFlag = false

        } else {
          this.verifyUpFlag = false
          this.errorFlag = true
        }
        this.reportBuriedPoint('page_view')
        this.reportBuriedPoint('orderid')
        this.verifyAutoCopyFlag()
      })
    },
    verifyVersion() {
      if (this.up && this.up.action) {
        this.version = 'sdk_v2'
      }
    },

    verifyAutoCopyFlag() {
      if (this.up && this.up.action) {
        let acs = this.up.action.split(',')
        if (acs.includes('2')) {
          toast.show('The UPI account has been copied.')
          this.current_copy = 'UPI'
          this.had_copy = true
          this.reportBuriedPoint('auto_copy_success')
        } else {
          this.reportBuriedPoint('auto_copy_failed')
        }
      }

    },

    handleVisibilityChange() {
      if (document.visibilityState === "visible") {
        if(this.paynow_flag) {
          this.reportBuriedPoint('re_enter_page')
        }
      }
    },

    customer() {

      console.log('customer')
      sendGtagEvent('click_customer_service')
      sendGtagEvent('click_customer_service_' + this.ga_time_str)
      this.reportBuriedPoint('click_cs')
      // sendGtagEvent("click_customer");
      window.fcWidget.on("widget:opened", (resp) => {
        console.log("Widget opened", resp);
        let fcIframe = document.getElementById("fc_widget");
        fcIframe.style.display = "block";
        //Function to do some task
      });
      // eslint-disable-next-line no-unused-vars
      window.fcWidget.on("widget:closed", (resp) => {
        let fcIframe = document.getElementById("fc_widget");
        fcIframe.style.display = "none";
        window.onWidgetExit && window.onWidgetExit();
        if (window.share) {
          window.share.exit();
        } else {
          // document.getElementById('page-text').innerHTML = 'Click button bottom right to customer service again, or click icon top left back to game.';
        }
      });
      // eslint-disable-next-line no-unused-vars
      window.fcWidget.on("widget:loaded", function (resp) {
        console.log("Widget Loaded");
        //Function to do some task
        // let fcIframe = document.getElementById("fc_widget");
        console.log("start to config fc iframe style");
        // fcIframe.style.display = 'none';
      });

      // if (location.href.indexOf("uid=") > 0) {

      // }

      // let startUid = location.href.indexOf("uid=");
      // let uid = location.href.substring(startUid + 4).split("&")[0];
      // let startPn = location.href.indexOf("pn=");

      let pn = 'svip';
      let uid = pn + "_" + this.uid;
      console.log('customer pn:' + pn + '; uid:' + uid)
      if (uid) {
        window.fcWidget.user.setProperties({
          firstName: uid,
          lastName: "",
          email: "",
          phone: "",
          status: "Active",
        });
      }

      window.fcWidget.init({
        token: this.customer_token,
        host: "https://wchat.freshchat.com",
        faqTags: {
          tags: ["Master Web"],
          filterType: "category",
        },
        config: {
          disableEvents: false,
          cssNames: {
            widget: "fc_frame",
            open: "fc_open",
            expanded: "fc_expanded",
          },
          showFAQOnOpen: false,
          hideFAQ: false,
          agent: {
            hideName: true,
            hidePic: false,
            hideBio: true,
          },
          headerProperty: {
            hideChatButton: false,
            hideHeader: false,
            appName: "Customer Service",
            appLogo:
                "https://de3yttvpadwpm.cloudfront.net/temp/cs-avatar-20201218.jpg",
            backgroundColor: "#2a4a96",
          },
          content: {
            placeholders: {
              search_field: "Search",
              // reply_field: 'Reply',
              reply_field: "Please enter the information",
              csat_reply: "Add your comments here",
            },
            actions: {
              csat_yes: "Yes",
              csat_no: "No",
              push_notify_yes: "Yes",
              push_notify_no: "No",
              tab_faq: "FAQs",
              tab_chat: "Chat",
              csat_submit: "Submit",
            },
            headers: {
              chat: "Chat with us",
              chat_help: "Reach out to us if you have any questions",
              faq: "FAQs",
              faq_help: "Browse our articles",
              faq_not_available: "No Articles Found",
              faq_search_not_available: "No articles were found for {{query}}",
              faq_useful: "Was this article helpful?",
              faq_thankyou: "Thank you for your feedback",
              faq_message_us: "Message Us",
              push_notification:
                  "Don't miss out on any replies! Allow push notifications?",
              csat_question: "Did we address your concerns??",
              csat_yes_question: "How would you rate this interaction?",
              csat_no_question: "How could we have helped better?",
              csat_thankyou: "Thanks for the response",
              csat_rate_here: "Submit your rating here",
              channel_response: {
                offline: "We are currently away. Please leave us a message",
                online: {
                  minutes: {
                    one: "Currently replying in {!time!} minutes ",
                    more: "Typically replies in {!time!} minutes",
                  },
                  hours: {
                    one: "Currently replying in under an hour",
                    more: "Typically replies in {!time!} hours",
                  },
                },
              },
            },
          },
        },
        locale: "en",
      });
      window.fcWidget.open();
    }
  },
  created() {
    this.up = this.getUp()
    this.parseSchedulingOrder()

    this.ga_time_in_id = setInterval(() => {
      this.ga_time = Math.round((this.ga_time + 0.5) * 10) / 10;

      // Determine ga_time_str based on ga_time value
      if (this.ga_time <= 60) {
        // For times less than or equal to 1 minute, update every 5 seconds
        this.ga_time_str = `${Math.floor(this.ga_time / 5) * 5}s`;
        if (this.ga_time_str === '0s') this.ga_time_str = '5s'; // Adjust for 0s
      } else {
        // For times greater than 1 minute, update every 60 seconds
        this.ga_time_str = `${Math.floor(this.ga_time / 60) * 60}s`;
      }
    }, 500);

  },
  beforeDestroy() {
    if(this.ga_time_in_id) {
      clearInterval(this.ga_time_in_id);
    }

    document.removeEventListener("visibilitychange", this.handleVisibilityChange)
  },
  mounted() {
    console.log('Component mounted, checking gtag:', typeof window.gtag);
    document.addEventListener("visibilitychange", this.handleVisibilityChange)
  },
  computed: {
    copyTips() {
      return this.current_copy === 'UPI'
    },
    amountMargin() {
      const length = this.amount.toString().length;
      if (length === 3) {
        return '-12px';
      } else if (length === 4) {
        return '2px';
      } else if (length === 5) {
        return '10px';
      } else if (length === 2) {
        return '-22px';
      } else if (length === 1) {
        return '-32px';
      } else {
        return '0px'
      }
    },


    steps() {
      return this.guide_steps[`${this.guide_type}_steps`] || [];
    },
    current_icon() {
      if(this.guide_type === 'paytmmp') {
        return '/assets/imgs/paytm.png'
      } else if (this.guide_type === 'phonepe') {
        return '/assets/imgs/phonepe.png'
      } else if (this.guide_type === 'gpay') {
        return '/assets/imgs/gpay.png'
      } else if (this.guide_type === 'BHIM') {
        return '/assets/imgs/bhim1.png'
      }
      return ''
    },
    youtube_steps_guide_media() {
      if (this.steps_t === 1) {
        return 'https://youtube.com/embed/4xZsAwxqLrA?si=ePT3TEJq95DzmSGX&autoplay=1'
      } else if (this.steps_t === 2) {
        return 'https://youtube.com/embed/F4MIYci8FDo?si=EFFpRtZyCNNsNfNo&autoplay=1'
      } else if (this.steps_t === 3) {
        return 'https://youtube.com/embed/xa1Inn5AHkE?si=dj2bTTWnigHU3wsh&autoplay=1'
      }
      return ''
    },

    youtube_media() {
      if (this.guide_type === 'paytmmp') {
        return ''
      } else if (this.guide_type === 'phonepe') {
        return 'https://youtube.com/embed/F4MIYci8FDo?si=EFFpRtZyCNNsNfNo&autoplay=1'
      } else if (this.guide_type === 'gpay') {
        return 'https://youtube.com/embed/xa1Inn5AHkE?si=dj2bTTWnigHU3wsh&autoplay=1'
      }
      return ''
    },
    current_guide_video() {
      if (this.guide_type === 'paytmmp') {
        return ''
      } else if (this.guide_type === 'phonepe') {
        return 'https://dy9ti2e3y6lyb.cloudfront.net/videos/PhonePe-HI.mp4'
      } else if (this.guide_type === 'gpay') {
        return 'https://dy9ti2e3y6lyb.cloudfront.net/videos/Gpay-HI.mp4'
      }
      return ''
    },

    current_steps_guide_video() {
      if (this.steps_t === 1) {
        return 'https://dy9ti2e3y6lyb.cloudfront.net/videos/Paytm-HI.mp4'
      } else if (this.steps_t === 2) {
        return 'https://dy9ti2e3y6lyb.cloudfront.net/videos/PhonePe-HI.mp4'
      } else if (this.steps_t === 3) {
        return 'https://dy9ti2e3y6lyb.cloudfront.net/videos/Gpay-HI.mp4'
      }
      return ''
    },
    current_guide_img1() {
      if(this.guide_type === 'paytmmp') {
        return '/assets/imgs/paytm_steps_big.png'
      } else if (this.guide_type === 'phonepe') {
        return '/assets/imgs/phonepe_steps_big.png'
      } else if (this.guide_type === 'gpay') {
        return '/assets/imgs/gpay_steps_big.png'
      } else if (this.guide_type === 'BHIM') {
        return '/assets/imgs/bhim_steps_big.png'
      }
      return ''
    },
    public_guide_img1() {
      if(this.steps_t === 1) {
        return '/assets/imgs/paytm_steps_big.png'
      } else if (this.steps_t === 2) {
        return '/assets/imgs/phonepe_steps_big.png'
      } else if (this.steps_t === 3) {
        return '/assets/imgs/gpay_steps_big.png'
      } else if (this.steps_t === 4) {
        return '/assets/imgs/bhim_steps_big.png'
      }
      return ''
    },

    isDisabled() {
      return !this.submitAmount || !this.submitUtrVal || !this.submitUPI
    },
    formattedMinutes() {
      return String(this.minutes).padStart(2, '0');
    },
    formattedSeconds() {
      return String(this.seconds).padStart(2, '0');
    }
  }
}


axios.interceptors.request.use(
    function (config) {
      removePending(config);
      addPending(config);
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
      removePending(response);
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
);

// axios.defaults.headers.common["token"] ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMTAwMjU2NDE3MCIsImlhdCI6MTY2MTQ5NDk2NywiZXhwIjoxNTY5MzAzMDk0OTY3fQ.bToWArF8mOTD_sA8Pooudvm5VFvMMApqwP7KsxCm7NOfucz7A22qWbDIhSHpQwjOy3xWz16WsAil67splhm3Qg";
// axios.defaults.headers.common['token'] = getCookie('token');
// axios.defaults.headers.common['token'] ='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMTAyMTM3NjkxNyIsImlhdCI6MTY2NTk5NDY4MSwiZXhwIjoxNTY5MzA3NTk0NjgxfQ.fWsw_oovuoFCVw-08xBjtuzVKIFKRSqXvL7sp98oPzeAwq9Pec0XKO30uHaAgTahhylIrzCkU0o894_bqUVLBQ'
let headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
function postRequest(url, data) {
  let result = axios({
    method: "post",
    url: url,
    data: JSON.stringify(data),
    baseURL: commonUrl,
    headers: headers,
  })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        return "exception=" + error;
      });
  return result;
}

// get request
// function getRequest(url, params) {
//   var result = axios({
//     method: "get",
//     url: url,
//     params: params,
//     baseURL: commonUrl,
//   })
//       .then(function (resp) {
//         return resp.data;
//       })
//       .catch(function (error) {
//         return "exception=" + error;
//       });
//   return result;
// }

const pending = new Map();

function addPending(config) {
  const url = [config.method, config.url].join("&");

  config.cancelToken =
      config.cancelToken ||
      new axios.CancelToken((cancel) => {
        if (!pending.has(url)) {
          // 将尚未存在的请求添加到 pending
          pending.set(url, cancel);
        }
      });
}

function removePending(config) {
  const url = [config.method, config.url].join("&");
  if (pending.has(url)) {
    // remove from pending request if exists
    const cancel = pending.get(url);
    cancel(url);
    pending.delete(url);
  }
}

function sendGtagEvent(action) {
  // 其他参数被设定为固定值
  console.log('sendgtagevent:', action)
  const category = 'button';
  const label = 'myButton';
  const value = 1;
  // eslint-disable-next-line no-undef
  if (!window.gtagLoaded) {
    console.error('gtag is not loaded yet!');
    return;
  }
  window.gtag('event', action, {
    'event_category': category,
    'event_label': label,
    'value': value
  });
  console.log('sendgtagevent_end:', action)

}
</script>